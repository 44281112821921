<template>
	<div class="pa-4">
		<v-data-table :search="search" :loading="loading" :headers="headers" :items="leaves.results" :next="leaves.next"
			:previous="leaves.previous" :single-expand="true" :expanded.sync="expanded" show-expand hide-default-footer
			class="elevation-4 rounded" loading-text="Loading leave records">
			<template #top>
				<v-toolbar flat height="auto" class="rounded-t" color="#ECEFF1">
					<v-toolbar-title class="table-toolbar-title pr-2" :class="{ 'px22': (title) }">
						{{ title || $route.name }}
					</v-toolbar-title>

					<v-responsive max-width="400" height="100%" class="pa-2"
						content-class="d-flex justify-center align-center">
						<text-field icon="mdi-magnify" v-model="search" name="search" label="Search records"
							class="full-width" :dense="true" />
					</v-responsive>
					<v-spacer />
					<v-responsive v-if="!url" max-width="350" height="100%" class="pa-2"
						content-class="d-flex justify-center align-center">
						<date-picker v-model="filter.date" label="Filter Date" name="date" icon="mdi-calendar"
							class="full-width" :dense="true" picker="DATE" />
						<v-fab-transition>
							<v-btn v-if="filter.date" icon @click="initialize"
								class="mx-1"><v-icon>mdi-close</v-icon></v-btn>
						</v-fab-transition>
					</v-responsive>
				</v-toolbar>
			</template>
			<template #item.leave_type="{ item }">
				<v-chip dark :color="findType(item).color">{{ findType(item).name }}</v-chip>
			</template>
			<template #item.status="{ item }">
				<v-tooltip bottom>
					<template #activator="{ on, attrs }">
						<v-icon dark :color="findStatus(item).color" v-bind="attrs" v-on="on">
							{{ findStatus(item).icon }}
						</v-icon>
					</template>
					<span>{{ findStatus(item).name }}</span>
				</v-tooltip>
			</template>
			<template #item.from_date="{ item }">
				{{ $formatUnix(item.from_date) }}
			</template>
			<template #item.to_date="{ item }">
				{{ $formatUnix(item.to_date) }}
			</template>
			<template v-slot:expanded-item="{ headers, item }">
				<td :colspan="headers.length">
					<v-row class="ma-0 pa-0" no-gutters>
						<v-col cols="12" class="pt-4 pl-4 d-flex align-center justify-space-between flex-wrap">
							<div class="leave-info px-1">
								<b class="id">#{{ item.id }}</b>
							</div>
							<div class="px-1">
								<v-btn class="past-approved ma-1" v-if="item.past_approved_leaves.length"
									@click="viewPastApprovalsFor(item)" small>
									Past Approved Leaves
								</v-btn>
								<v-btn v-if="$helper.isAdmin() && ['PE', 'RC', 'RE'].includes(item.status)"
									color="success lighten-1" small @click="approveLeave(item)" class="ma-1">
									<v-icon left>mdi-check</v-icon>
									Approve
								</v-btn>
								<v-btn v-if="$helper.isAdmin() && ['RE', 'RC'].includes(item.status)"
									color="error lighten-1" small @click="rejectLeave(item)" class="ma-1">
									<v-icon left>mdi-close</v-icon>
									Reject
								</v-btn>
								<v-btn v-if="$helper.isManagerOrAdmin() && ['PE', 'RE'].includes(item.status)"
									color="indigo lighten-1" small dark @click="recommendLeave(item)" class="ma-1">
									<v-icon left>mdi-account-voice</v-icon>
									Recommend
								</v-btn>
								<v-btn v-if="$helper.isManagerOrAdmin() && ['RC'].includes(item.status)" color="indigo" dark
									@click="revokeLeaveRecommendation(item)" class="ma-1" small>
									<v-icon left>mdi-account-voice-off</v-icon>
									Reject
								</v-btn>
							</div>
						</v-col>
						<v-col>
							<list-item subtitle="Approved By" :title="(item['approved_by_name']) || '-'" />
						</v-col>
						<v-col>
							<list-item subtitle="Recommended By" :title="(item['recommended_by_name']) || '-'" />
						</v-col>
						<v-col>
							<list-item subtitle="Rejected By" :title="(item['rejected_by_name']) || '-'" />
						</v-col>
						<v-col>
							<list-item subtitle="Status Message" :title="(item.status_message) || '-'" />
						</v-col>
					</v-row>
				</td>
			</template>
			<template #item.actions="{ item }">
				<tooltip-btn v-if="$helper.isAdmin()" icon="mdi-pencil" color="primary lighten-1" @click="editItem(item)"
					tooltip="Edit Record" />
				<tooltip-btn v-if="$helper.isAdmin()" color="error lighten-1" @click="deleteItem(item)" icon="mdi-delete"
					tooltip="Delete Record" />
			</template>
			<template #no-data>
				<v-btn color="primary" @click="initialize">
					Reset
				</v-btn>
			</template>
		</v-data-table>
		<leave-dialog @init="initialize" />
		<delete-dialog :model="model" @init="initialize" />
		<past-approved-leaves />
		<div class="my-2 d-flex paginate-btn-group">
			<v-btn class="paginate-btn" v-if="leaves.previous" @click="changePage(leaves.previous)">Previous</v-btn>
			<v-btn class="paginate-btn" v-if="leaves.next" @click="changePage(leaves.next)">Next</v-btn>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import TableMixin from "@/mixins/TableMixin.js";

export default {
	name: "LeaveTable",
	components: {
		PastApprovedLeaves: () => import("@/components/Dialogs/PastApprovedLeaves"),
		LeaveDialog: () => import("@/components/Dialogs/LeaveUDialog"),
	},
	props: {
		url: {
			required: false,
			type: String
		},
		title: {
			required: false,
			type: String
		}
	},
	mixins: [TableMixin],
	data: () => ({
		model: "leave",
		headings: [
			{ text: "User", value: "requested_by_name" },
			{ text: "Reason", value: "reason", sortable: false },
			{ text: "Type", value: "leave_type", sortable: false, align: "center" },
			{ text: "From Date", value: "from_date" },
			{ text: "To Date", value: "to_date" },
			{ text: "Status", value: "status", sortable: true },
			{ text: "", value: "data-table-expand" },
		]
	}),
	computed: {
		...mapGetters({
			leaves: "leave/list",
		})
	},
	methods: {
		...mapMutations("leave", ["SET_LIST", "SET_FOR_EDIT", "SET_DIALOG_STATE", "SET_FORM_ERRORS"]),
		...mapMutations("toDelete", ["SET_ITEM_TO_DELETE"]),
		findType(item) {
			return this.$helper.LEAVE_TYPES.find(type => type.code === item.leave_type)
		},
		findStatus(item) {
			return this.$helper.STATUS_CHOICES.find(type => type.code === item.status)
		},
		async leaveAction(item, url, action, err) {
			this.loading = true
			try {
				const response = await this.$axios.post(
					url,
					{
						leaveID: item.id,
						action: action
					}
				)
				await this.initialize()
				await this.openSnack({ text: response.msg, color: "success" })
			} catch (e) {
				await this.openSnack({ text: err })
			} finally {
				this.loading = false
			}
		},
		async changePage(url) {
			console.log(url)
			await this.nextprev(url);
		},
		async approveLeave(item) {
			await this.leaveAction(
				item, this.$urls.leave.approve, "approve",
				"Leave approval failed. Please try again"
			)
		},
		async rejectLeave(item) {
			await this.leaveAction(
				item, this.$urls.leave.approve, "reject",
				"Leave reject action failed. Please try again"
			)
		},
		async recommendLeave(item) {
			await this.leaveAction(
				item, this.$urls.leave.recommend,
				"recommend", "Leave recommendation failed. Please try again"
			)
		},
		async revokeLeaveRecommendation(item) {
			await this.leaveAction(
				item, this.$urls.leave.recommend,
				"reject", "Leave recommendation removal failed. Please try again"
			)
		},
		viewPastApprovalsFor(item) {
			this.$store.dispatch("setPastApprovalDialog", item.past_approved_leaves)
		}
	}
}
</script>
<style lang="scss">
@import "src/styles/fonts";

.paginate-btn{
	background: #fff !important;
}

.leave-info {
	display: flex;
	align-items: center;

	.id {
		font-size: 1.2rem;
		line-height: 1.4rem;
	}

	.pipe {
		margin: 0 5px;
		background-color: grey;
		height: 25px;
		width: 1px;
	}

	.past-approved {
		color: grey;
		cursor: pointer;

		&:hover {
			color: darkslategrey;
		}
	}
}

.paginate-btn-group {
	gap: 1ch;
}
</style>
